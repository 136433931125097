import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { FormControlErrorMessageModule } from '@portal-core/forms/components/form-control-error-message/form-control-error-message.module';
import { NgxMatColorCanvasComponent } from '@portal-core/ui/color-picker/components/color-canvas/color-canvas.component';
import { NgxMatColorSliderComponent } from '@portal-core/ui/color-picker/components/color-canvas/color-slider/color-slider.component';
import { NgxMatColorCollectionComponent } from '@portal-core/ui/color-picker/components/color-collection/color-collection.component';
import { NgxMatColorPaletteComponent } from '@portal-core/ui/color-picker/components/color-palette/color-palette.component';
import { NgxMatColorPickerInput } from '@portal-core/ui/color-picker/components/color-picker/color-input.component';
import { NGX_MAT_COLOR_PICKER_SCROLL_STRATEGY_FACTORY_PROVIDER, NgxMatColorPickerComponent, NgxMatColorPickerContentComponent } from '@portal-core/ui/color-picker/components/color-picker/color-picker.component';
import { NgxMatColorToggleComponent } from '@portal-core/ui/color-picker/components/color-toggle/color-toggle.component';
import { NumericColorInputDirective } from '@portal-core/ui/color-picker/directives/numeric-color-input.directive';
import { ColorAdapter } from '@portal-core/ui/color-picker/services/color-adapter';
import { MCColorPickerComponent } from './color-picker.component';

@NgModule({
  declarations: [
    NgxMatColorPaletteComponent,
    NgxMatColorCanvasComponent,
    NgxMatColorCollectionComponent,
    NgxMatColorSliderComponent,
    NumericColorInputDirective,
    NgxMatColorPickerContentComponent,
    NgxMatColorPickerComponent,
    NgxMatColorToggleComponent,
    NgxMatColorPickerInput,
    MCColorPickerComponent
  ],
  imports: [
    CommonModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    MatCardModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    PortalModule,
    MatIconModule,
    FormControlErrorMessageModule,
  ],
  exports: [
    NgxMatColorToggleComponent,
    NgxMatColorPickerInput,
    NgxMatColorPickerComponent,
    MCColorPickerComponent
  ],
  entryComponents: [
    NgxMatColorPickerContentComponent
  ],
  providers: [
    ColorAdapter,
    NGX_MAT_COLOR_PICKER_SCROLL_STRATEGY_FACTORY_PROVIDER
  ]
})
export class MCColorPickerModule { }
