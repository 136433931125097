import { keyBy } from 'lodash';

/**
 * Merge multiple arrays of objects into a new array, using a property of the objects as the merge key.
 *
 * @param key The property to use as the merge key when combining the arrays.
 * @param arrays A list of arrays to merge.
 * @returns A new array containing the combination of all items in all arrays, merged using the provided merge key.
 */
export function mergeArraysByKey<T>(key: string, ...arrays: T[][]): T[] {
  return Object.values(
    Object.assign({}, ...arrays.map(array => keyBy(array, key)))
  );
}

/**
 * Returns the given array if the array is not empty. Otherwise, returns null.
 * @param value An array to check.
 * @returns The given array if the array is not empty. Otherwise, returns null.
 */
export function array(value: any[]): any[] | null {
  return value?.length > 0 ? value : null;
}

/**
 * Return the first value if the array is not empty. Otherwise, returns null.
 * @param value An array to get the first value from.
 * @returns The first value if the array is not empty. Otherwise, returns null.
 */
export function firstOrNull(value: string[]): string {
  return value?.[0] ?? null;
}

/**
 * Return an array with the only item if the specified value is not empty. Otherwise, returns empty array.
 * @param value A string value to be used as the first item of a new array.
 * @returns An array with the only item if the specified value is not empty. Otherwise, returns empty array.
 */
export function makeArray(value: string): string[] {
  return value ? [value] : [];
}
