export enum ErrorCode {
  // API Generic Errors
  UnknownError = 0,
  IdentityError = 1,
  ModelInvalidError = 2,
  CreateError = 3,
  CreateBulkError = 4,
  TooManyRequestsError = 5,

  // API User Errors
  UserAddPasswordError = 10001,
  UserConfirmEmailError = 10002,
  UserTokenError = 10003,
  UserChangePasswordError = 10004,
  UserPasswordSetError = 10005,
  UserLoginError = 10006,
  UserUnauthorizedError = 10007,
  UserOAuthNotRegisteredError = 10008,
  UserOAuthSecretNotSentError = 10009,
  UserOAuthSecretInvalidError = 10010,
  UserOAuthInactiveError = 10011,
  UserNotFoundError = 10012,
  UserIncorrectCredentialsError = 10013,
  UserExpiredPasswordError = 10014,
  UserLockedOutError = 10015,
  UserDeactivatedError = 10016,
  UserNotConfirmedError = 10017,
  UserUpdatePasswordError = 10018,
  UserClaimNotFoundError = 10019,
  UserCreatedError = 10020,
  UserLatestViewedVersionError = 10021,
  UserBulkUploadError = 10022,
  UserBulkSeatError = 10023,
  UserBulkCSVMalformedError = 10024,
  UserOldPasswordHistoryError = 10025,
  UserMinPasswordLengthError = 10026,
  UserExistingPasswordError = 10027,
  UserEmailAlreadyConfirmedError = 10028,
  UserEmailIsPasswordError = 10029,
  UserNonSSOAdminLoginError = 10030,

  // API License Errors
  LicenseExpiredError = 20001,
  LicenseInvalidTeamError = 20002,
  LicensePurchaseInProgressError = 20003,
  LicenseProjectNotFoundError = 20004,
  LicenseTeamNotFoundError = 20005,
  LicenseNotFoundError = 20006,
  LicenseUserAlreadyExistsError = 20007,
  LicenseUserInvalidEmailError = 20008,

  // API Project Errors
  ProjectNotActiveError = 30001,
  ProjectMultipleLicenseError = 30002,
  ProjectFileNotFoundError = 30003,
  ProjectNotDeletedError = 30004,
  ProjectNotDeletedFromSourceControlError = 30005,
  ProjectNotAuthorizedError = 30006,

  // API Review Errors
  ReviewNotFoundError = 50001,
  ReviewProjectNotFoundError = 50002,
  ReviewPathNotFoundError = 50003,

  // API Analyzer Errors
  AnalyzerProjectTooLargeError = 60001,
  AnalyzerScanCodeError = 60002,

  // API Site Errors
  SiteNameRequiredError = 70001,
  SiteNameExistsError = 70002,
  SiteRequiredError = 70003,
  SiteNotFound = 70004,
  SiteStyleMisformattedError = 70005,
  SiteStyleNotFoundError = 70006,

  // API Team Errors
  TeamMisformattedError = 80001,
  TeamNameRequiredError = 80002,
  TeamNameExistsError = 80003,
  TeamMultipleLicenseError = 80004,
  TeamCreatedError = 80005,
  TeamNotFoundError = 80006,

  // API Git Errors
  GitFileContentNotProvidedError = 90001,
  GitFileIdNotProvidedError = 90002,
  GitFilePathNotProvidedError = 90003,
  GitUserNotFoundError = 90004,
  GitKeyExistsError = 90005,
  GitUserTokenError = 90006,
  GitUserUpdateError = 90007,
  GitPostError = 90008,
  GitGetError = 90009,
  GitPutError = 90010,
  GitDeleteError = 90010,

  // API Collab Errors
  CollabTypeNotValidError = 100001,

  // API Output Analytics Errors
  OutputAnalyticsSearchDeleteError = 110001,
  OutputAnalyticsLogTopicViewError = 110002,
  OutputAnalyticsLogSearchError = 110003,
  OutputAnalyticsExcludedIPsError = 110004,
  OutputAnalyticsKeyError = 110005,

  // API Audit Errors
  AuditSendError = 120001,

  // API Build Errors
  BuildCodeError = 130001,

  // API Vanity Url Errors
  VanityUrlIsIntegerError = 140001,
  VanityUrlIsGuidError = 140002,
  VanityUrlPatternError = 140003,
  VanityUrlExistsError = 140004,
  VanityUrlIsReservedError = 140005,
  VanityUrlIsTooLongError = 140006,
  VanityUrlDoesNotExistError = 140007,

  // API License Host Map Errors
  LicenseHostMapDomainExistsError = 150001,
  LicenseHostMapDomainNotFoundError = 150002,
  LicenseHostMapDomainInvalidIPError = 150003,
  LicenseHostMapDomainInvalidCNameError = 150004,

  // API Content Security Policy Errors
  ContentSecurityPolicyNotUniqueError = 170002,

  // API Translation Package Errors
  TranslationPackageStatusNotAllowed = 190001,

  // Client Errors (negative so as not to conflict with api error codes)
  GenericLoginError = -10000, // Start with a low value so that the auto-increment will never reach a non-negative value
  LoginLoadError,
  UnknownLoginError,
  IdleLogoutError,
  GenericCompleteInviteError,
  GenericUpdatePasswordError,
  GenericForgotPasswordError,
  GenericResetPasswordError,
  GenericRequestSiteAccessError,
  GenericRequestLicenseAccessError,
  GenericLoadUserSitesError,
  GenericLoadSiteStylesError,
  GenericSaveSiteStylesError,
  GenericSaveContentSecurityPolicyError,
  GenericLoadContentSecurityPolicyError,
  GenericLoadSiteStylesListError,
  GenericLicenseVanityVerificationError,
  GenericLicenseListError,
  GenericLoadCentralInstancesError,
  NotFoundError,

  // Auth Errors
  AuthRedirectRequired,
  AuthMissingRole,

  // Client Validation Errors
  ValidationUnknownError,
  ValidationPhoneNumberRequired,
  ValidationPhoneNumberInvalid,
  ValidationPhoneNumberTooLong,
  ValidationPhoneNumberTooShort,
  ValidationEmailRequired,
  ValidationEmailInvalid,
  ValidationEmailTooLong,
  ValidationConfirmEmailRequired,
  ValidationConfirmEmailMismatch,
  ValidationPasswordRequired,
  ValidationPasswordTooShort,
  ValidationNewPasswordMustBeDifferent,
  ValidationConfirmPasswordRequired,
  ValidationConfirmPasswordMismatch,
  ValidationTermsOfUseRequired,
  ValidationKeyLicenseLabelRequired,
  ValidationLicenseNameRequired,
  ValidationLicenseNameTooLong,
  ValidationLicenseNameBlank,
  ValidationSeatsRequired,
  ValidationSeatsNumberTooLarge,
  ValidationSeatsNumberInvalid,
  ValidationSeatsTooLow,
  ValidationStorageRequired,
  ValidationStorageTooLarge,
  ValidationStorageTooLow,
  ValidationCreditCardNumberRequired,
  ValidationCreditCardNumberInvalid,
  ValidationCreditCardExpirationRequired,
  ValidationCreditCardExpirationInvalid,
  ValidationCreditCardCodeRequired,
  ValidationCreditCardCodeInvalid,
  ValidationUserFirstNameRequired,
  ValidationUserFirstNameTooLong,
  ValidationUserFirstNameBlank,
  ValidationUserLastNameRequired,
  ValidationUserLastNameTooLong,
  ValidationUserLastNameBlank,
  ValidationUserInitialsRequired,
  ValidationUserInitialsTooLong,
  ValidationUserTitleTooLong,
  ValidationUserLocationTooLong,
  ValidationUserDepartmentTooLong,
  ValidationUserSeatTypeRequired,
  ValidationUserSeatTypeTooMany,
  ValidationBillingCompanyRequired,
  ValidationAddressRequired,
  ValidationCityRequired,
  ValidationPostalCodeRequired,
  ValidationLicenseHostMapDomainRequired,
  ValidationVanityRequired,
  ValidationVanityInvalid,
  ValidationSiteVanityInvalid,
  ValidationChecklistNameRequired,
  ValidationChecklistNameTooLong,
  ValidationOutputAnalyticsNameRequired,
  ValidationOutputAnalyticsNameTooLong,
  ValidationOutputAnalyticsDescriptionTooLong,
  ValidationSiteNameRequired,
  ValidationSiteNameTooLong,
  ValidationBuildIdRequired,
  ValidationBuildIdInvalid,
  ValidationTargetIdRequired,
  ValidationTargetIdInvalid,
  Validation360AngleTooLow,
  Validation360AngleTooHigh,
  ValidationImageRequired,
  ValidationImageFileTypeInvalid,
  ValidationPfxRequired,
  ValidationPfxFileTypeInvalid,
  ValidationPfxFileContentInvalid,
  ValidationCsvRequired,
  ValidationCsvFileTypeInvalid,
  ValidationCsvEmpty,
  ValidationCsvItemsInvalid,
  ValidationCsvTooLarge,
  ValidationSiteRequired,
  ValidationSiteDoesNotExist,
  ValidationSiteThemeNameRequired,
  ValidationSiteThemeNameTooLong,
  ValidationContentSecurityPolicyDirectiveInvalid,
  ValidationContentSecurityPolicyNameRequired,
  ValidationContentSecurityPolicyNameTooLong,
  ValidationContentSecurityPolicyNameBlank,
  ValidationSiteLogoImageTooLarge,
  ValidationAvatarImageTooLarge,
  ValidationTeamNameRequired,
  ValidationTeamNameTooLong,
  ValidationViewerTeamRequired,
  ValidationViewerTeamsMissingLivePrivateSite,
  ValidationNumberRequired,
  ValidationDateRequired,
  ValidationDateFormat,
  ValidationTaskBoardTitleRequired,
  ValidationTaskBoardTitleTooLong,
  ValidationTaskBoardTitleBlank,
  ValidationTaskBoardDescriptionTooLong,
  ValidationDaysRequired,
  ValidationLanguagesRequired,
  ValidationTranslationPackagesNameRequired,
  ValidationTranslationPackagesNameNotValid,
  ValidationTranslationPackagesNameMaxLength,
  ValidationTargetPathRequired,
  ValidationTargetNotSupported,
  ValidationTimeZoneRequired,
  ValidationTimeRequired,
  ValidationFilePathTooLong,
  ValidationFilePathInvalid,
  ValidationFilePathRequired,
  ValidationFileNameRequired,
  ValidationFilePathNotChanged,
  ValidationFilePathWhitespace,
  ValidationFilePathExtension,
  ValidationFilePathExtensionPeriod,
  ValidationFolderPathTooLong,
  ValidationFolderPathInvalid,
  ValidationFolderPathRequired,
  ValidationFolderNameRequired,
  ValidationFileNameSpecialChars,
  ValidationFolderNameWhitespace,
  ValidationFolderPathWhitespace,
  ValidationFileTypeRequired,
  ValidationFileNameWhitespace,
  ValidationFileTemplateRequired,
  ValidationCommitMessageRequired,
  ValidationCommitMessageTooLong,
  ValidationLicenseDefaultTeamRequired,
  ValidationSamlEndpointRequired,
  ValidationSamlIdpIssuerRequired,
  ValidationSamlPublicCertRequired,
  ValidationFilesRequired,
  ValidationFilesTooLarge,
  ValidationReviewPackageNameRequired,
  ValidationReviewPackageNameTooLong,
  ValidationReviewPackageDescriptionTooLong,
  ValidationProjectRequired,
  ValidationBranchRequired,
  ValidationOpenAIKey,
  ValidationMultimediaLinkRequired,
  ValidationSupportedExternalMultimediaLink,
  ValidationConditionTagNameRequired,
  ValidationConditionTagNameBlank,
  ValidationConditionTagNameExists,
  ValidationColorPickerValueRequired,
  ValidationColorPickerValueInvalid,
  ValidationVariablesRequired,
  ValidationVariableNameRequired,
  ValidationVariableNameBlank,
  ValidationVariableNameExists,
  ValidationVariableDefinitionRecursive,
  ValidationVariableDefinitionIncorrectFormat,
  ValidationVariableDefinitionExists,
  ValidationPropertyRequired,
  ValidationPropertyInvalidFormat,
  ValidationPropertyFontMissing,
  ValidationPropertyFileMissing,
  ValidationIncorrectStylesheetType,
  ValidationInvalidTargetSkin,
}
