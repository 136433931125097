import { SetBlockTypeExcludeAttrs } from '@common/flare/constants/set-block-type-exclude-attrs.constant';
import { doWrapInList, liftOutOfList, liftToOuterList } from '@common/prosemirror/commands/list.prosemirror';
import { resolvedPosFind, resolvedPosFindNode } from '@common/prosemirror/model/resolved-pos';
import { setBlockType } from '@common/prosemirror/transform/node';
import { Fragment, Node, NodeRange, NodeType, Slice } from 'prosemirror-model';
import { liftListItem } from 'prosemirror-schema-list';
import { Command, EditorState, NodeSelection, Selection } from 'prosemirror-state';
import { CellSelection } from 'prosemirror-tables';
import { ReplaceAroundStep, canSplit, findWrapping } from 'prosemirror-transform';

/**
 * wrapInList
 * Builds a command that wraps a node in a list.
 * If wrapping plain text (mcCentralContainer) then the text is wrapped in the default block node for a list item.
 */
// export function wrapInList(listType: NodeType, itemChildType: NodeType, replaceChildType: NodeType, attrs?: Dictionary): Command {
//   return wrapInDefinitionList(listType, itemChildType);
//   // return function(state: EditorState, dispatch?: ProsemirrorDispatcher): boolean {
//   //   const { $from, $to } = state.selection;

//   //   let range = $from.blockRange($to);
//   //   if (!range) {
//   //     return false;
//   //   }

//   //   let doJoin = false;
//   //   let outerRange = range;

//   //   // This is at the top of an existing list item
//   //   if (range.depth >= 2 && $from.node(range.depth - 1).type.compatibleContent(listType) && range.startIndex === 0) {
//   //     // Don't do anything if this is the top of the list
//   //     if ($from.index(range.depth - 1) === 0) {
//   //       return false;
//   //     }

//   //     const $insert = state.doc.resolve(range.start - 2);
//   //     outerRange = new NodeRange($insert, $insert, range.depth);

//   //     if (range.endIndex < range.parent.childCount) {
//   //       range = new NodeRange($from, state.doc.resolve($to.end(range.depth)), range.depth);
//   //     }

//   //     doJoin = true;
//   //   }

//   //   const wrap = findWrapping(outerRange, listType, attrs, range);
//   //   if (!wrap) {
//   //     return false;
//   //   }

//   //   if (dispatch) {
//   //     const tr = state.tr;

//   //     // Change the block nodes that are being wrapped by the list into the item child type if they are the same type of node as the replace child type
//   //     // if (range.$from.parent.type === replaceChildType) {
//   //     //   tr.setBlockType(range.start, range.end, itemChildType);
//   //     // }

//   //     let childPos = range.start;
//   //     for (let i = range.startIndex; i < range.endIndex; i += 1) {
//   //       const child = range.parent.child(i);
//   //       if (child.type === replaceChildType) {
//   //         tr.setBlockType(childPos, childPos + child.nodeSize, itemChildType);
//   //       }
//   //       childPos += child.nodeSize;
//   //     }

//   //     // Wrap the block nodes in the list
//   //     doWrapInList(tr, range, wrap, doJoin, listType);

//   //     dispatch(tr.scrollIntoView());
//   //   }

//   //   return true;
//   // };
// }

/**
 * splitListItem
 * Builds a command that splits a list item.
 * Currently splitListItem does not split when the cursor is inside an inline node.
 */
export function splitListItem(itemType: NodeType): Command {
  return function (state: EditorState, dispatch?: ProsemirrorDispatcher): boolean {
    const { $from, $to } = state.selection;

    if (state.selection instanceof NodeSelection) {
      const node = state.selection.node;

      if ((node && node.isBlock) || $from.depth < 2 || !$from.sameParent($to)) {
        return false;
      }
    }

    // If the selection is not inside a list item then return false
    const listItemResolvedPosInfo = resolvedPosFind($from, node => node.type === itemType);
    if (!listItemResolvedPosInfo) {
      return false;
    }

    const listItemNode = listItemResolvedPosInfo.node;

    // If this is an empty block
    if ($from.parent.content.size === 0 && $from.node(-1).childCount === $from.indexAfter(-1)) {
      // If this is not a nested list then return false
      if ($from.depth === 3 || $from.node(-3).type !== itemType || $from.index(-2) !== $from.node(-2).childCount - 1) {
        return false;
      }

      if (dispatch) {
        // If there are multiple children in this list item then keep the newly created item in the same list
        const keepItemInSameList = $from.index(-1) > 0;

        // Use this code to create the next list item using the same node type the the cursor is in
        // const nextNode = listItemNode.type.createAndFill(null, $from.node(listItemResolvedPosInfo.depth + 1));

        // Create the next list item using the same node type as the current list item's first child
        const nextNode = listItemNode.type.createAndFill(null, listItemNode.content.firstChild?.type?.createAndFill() ?? listItemNode.contentMatchAt(0).defaultType.createAndFill());

        const insertPos = $from.after(listItemResolvedPosInfo.depth - (keepItemInSameList ? 0 : 2));
        const tr = state.tr.insert(insertPos, nextNode);
        tr.setSelection(Selection.near(tr.doc.resolve(insertPos + ($from.depth - listItemResolvedPosInfo.depth))));
        dispatch(tr.scrollIntoView());
      }

      return true;
    } else {
      let nextType: NodeType = null;
      // If the cursor is at the end of the item
      if ($to.pos === $from.end()) {
        // Then use the same node type as the first node in the list item if it exists. Fallback to the default type for the list item
        nextType = listItemNode.content.firstChild?.type ?? listItemNode.contentMatchAt(0).defaultType;
      }

      const tr = state.tr.delete($from.pos, $to.pos);
      const types = nextType ? [null, { type: nextType }] : undefined;
      const nextDepth = $from.depth - listItemResolvedPosInfo.depth + 1;

      if (!canSplit(tr.doc, $from.pos, nextDepth, types)) {
        return false;
      }

      if (dispatch) {
        dispatch(tr.split($from.pos, nextDepth, types).scrollIntoView());
      }

      return true;
    }
  };
}

/**
 * wrapInList
 * Builds a command that wraps a node in a list (eg ul/ol/dl)
 * The block nodes being wrapped are converted into itemChildType if possible.
 */
export function wrapInList(listType: NodeType, itemChildType: NodeType, attrsBehavior: 'keep' | 'move-to-item' | 'remove'): Command {
  return function (state: EditorState, dispatch?: ProsemirrorDispatcher): boolean {
    // Wrapping a cell selection in a list is not supported
    if (state.selection instanceof CellSelection) {
      return false;
    }

    const { $from, $to } = state.selection;

    let range = $from.blockRange($to);
    if (!range) {
      return false;
    }

    let doJoin = false;
    let outerRange = range;

    // This is at the top of an existing list item
    if (range.depth >= 2 && $from.node(range.depth - 1).type.compatibleContent(listType) && range.startIndex === 0) {
      // Don't do anything if this is the top of the list
      if ($from.index(range.depth - 1) === 0) {
        return false;
      }

      const $insert = state.doc.resolve(range.start - 2);
      outerRange = new NodeRange($insert, $insert, range.depth);

      if (range.endIndex < range.parent.childCount) {
        range = new NodeRange($from, state.doc.resolve($to.end(range.depth)), range.depth);
      }

      doJoin = true;
    }

    const wrap = findWrapping(outerRange, listType, null, range);
    if (!wrap) {
      return false;
    }

    if (dispatch) {
      const tr = state.tr;
      const attrs: Dictionary[] = [];

      // Change the block nodes that are being wrapped by the list into the item child type
      if (attrsBehavior === 'keep') {
        let childPos = range.start;
        for (let i = range.startIndex; i < range.endIndex; i += 1) {
          const child = range.parent.child(i);
          // Copy the attrs over to the new block but exclude unwanted attrs
          setBlockType(tr, childPos, childPos + child.nodeSize, itemChildType, SetBlockTypeExcludeAttrs);
          childPos += child.nodeSize;
        }
      } else if (attrsBehavior === 'move-to-item') {
        // Store the attrs to be copied over to the list items after the content is wrapped in the list
        for (let i = range.startIndex; i < range.endIndex; i += 1) {
          const child = range.parent.child(i);
          attrs.push(child.attrs);
        }
        // Do not copy the attrs to the new block because they are going to be moved to the item instead
        tr.setBlockType(range.start, range.end, itemChildType);
      } else if (attrsBehavior === 'remove') {
        // Remove the attrs when changing the block type
        tr.setBlockType(range.start, range.end, itemChildType);
      }

      // Wrap the block nodes in the list
      doWrapInList(tr, range, wrap, doJoin, listType);

      // Move the attrs to the list items
      if (attrsBehavior === 'move-to-item') {
        const listNode = tr.doc.nodeAt(outerRange.start);

        let childPos = outerRange.start + 1;
        for (let i = 0; i < listNode.childCount; i += 1) {
          const child = listNode.child(i);
          tr.setNodeMarkup(childPos, null, attrs[i]);
          childPos += child.nodeSize;
        }
      }

      dispatch(tr.scrollIntoView());
    }

    return true;
  };
}

/**
 * Creates a command that splits a definition list's items at the current selection.
 * This function is a modified version of ProseMirror's splitListItem.
 * @param termItemType The NodeType for the definition list's term node.
 * @param descriptionItemType The NodeType for the definition list's description node.
 */
export function splitDefinitionListItem(termItemType: NodeType, descriptionItemType: NodeType): Command {
  return function (state: EditorState, dispatch?: ProsemirrorDispatcher): boolean {
    const { $from, $to } = state.selection;

    if (state.selection instanceof NodeSelection) {
      const node = state.selection.node;

      if ((node && node.isBlock) || $from.depth < 2 || !$from.sameParent($to)) {
        return false;
      }
    }

    // If the selection is not inside a definition list item then return false
    const listItemResolvedPosInfo = resolvedPosFind($from, node => node.type === termItemType || node.type === descriptionItemType);
    if (!listItemResolvedPosInfo) {
      return false;
    }

    const listItemNode = listItemResolvedPosInfo.node;
    const listNodeDepth = listItemResolvedPosInfo.depth - 1;
    const listNode = $from.node(listNodeDepth);

    // If this is an empty block
    if ($from.parent.content.size === 0 && $from.node(-1).childCount === $from.indexAfter(-1)) {
      if (dispatch) {
        // If a description item is being split
        if (listItemNode.type === descriptionItemType) {
          // Then replace the description item with a term item
          dispatch(state.tr.setNodeMarkup($from.before(-1), termItemType, listItemNode.attrs, listItemNode.marks).scrollIntoView());
        } else if (listItemNode.type === termItemType) {
          const tr = state.tr;
          let insertPos: number;

          // If this is the last item in the list
          if ($from.index(listNodeDepth) === listNode.childCount - 1) {
            // The next node type should be the default type in the parent of the definition list
            const listParentNode = $from.node(listItemResolvedPosInfo.depth - 2);
            const nextNodeType = listParentNode.contentMatchAt(0).defaultType;

            // If this is also the first item in the list (which means the list is essentially empty)
            if ($from.index(listNodeDepth) === 0) {
              // Replace the list with a new node
              insertPos = $from.start(listNodeDepth);
              tr.replaceRangeWith(insertPos, $from.end(listNodeDepth), nextNodeType.createAndFill());
            } else {
              // Delete the term item
              tr.deleteRange($from.start(listItemResolvedPosInfo.depth), $from.end(listItemResolvedPosInfo.depth));

              // Insert a new node after the list that uses the same content as the node in the term item
              insertPos = tr.mapping.map($from.after(listNodeDepth));
              tr.insert(insertPos, nextNodeType.createAndFill());
            }
          } else {
            // Else insert a new node in the term item that is the same type of node as the selection position
            insertPos = $from.after(listItemResolvedPosInfo.depth + 1);
            tr.insert(insertPos, $from.node(listItemResolvedPosInfo.depth + 1));
          }

          // Set the selection in the new node
          tr.setSelection(Selection.near(tr.doc.resolve(insertPos + ($from.depth - listItemResolvedPosInfo.depth))));
          dispatch(tr.scrollIntoView());
        } else {
          let wrap = Fragment.empty;
          const keepItem = $from.index(-1) > 0;

          // Build a fragment containing empty versions of the structure from the outer list item to the parent node of the cursor
          for (let d = $from.depth - (keepItem ? 1 : 2); d >= $from.depth - 3; d -= 1) {
            wrap = Fragment.from($from.node(d).copy(wrap));
          }

          // Add a second list item with an empty default start node
          wrap = wrap.append(Fragment.from(termItemType.createAndFill()));

          const tr = state.tr.replace($from.before(keepItem ? null : -1), $from.after(-3), new Slice(wrap, keepItem ? 3 : 2, 2));

          tr.setSelection(Selection.near(tr.doc.resolve($from.pos + (keepItem ? 3 : 2))));
          dispatch(tr.scrollIntoView());
        }
      }

      return true;
    } else {
      let nextItemType: NodeType = null;

      // If the cursor is at the start of a term item
      if (listItemNode.type === termItemType && $to.pos === $from.start()) {
        nextItemType = termItemType;
      } else {
        nextItemType = descriptionItemType;
      }

      // Use the same node type as the first node in the item if it exists. Fallback to the default type for the item
      const nextItemChildType = listItemNode.content.firstChild?.type ?? listItemNode.contentMatchAt(0).defaultType;

      const tr = state.tr.delete($from.pos, $to.pos);
      let types = nextItemChildType ? [null, { type: nextItemChildType }] : undefined;
      const nextDepth = $from.depth - listItemResolvedPosInfo.depth + 1;

      if (!canSplit(tr.doc, $from.pos, nextDepth, types)) {
        return false;
      }

      if (dispatch) {
        // Change the first type being inserted into a description item
        types = [{ type: nextItemType }, nextItemChildType ? { type: nextItemChildType } : null];
        dispatch(tr.split($from.pos, nextDepth, types).scrollIntoView());
      }

      return true;
    }
  };
}

/**
 * Creates a command that changes the type of a node from a definition term to a definition description or vice versa.
 * @param nodeType The definition list item node type to change into.
 * @param otherNodeType The definition list item node type to change from.
 */
export function setDefinitionListItemType(nodeType: NodeType, otherNodeType: NodeType): Command {
  return function (state: EditorState, dispatch?: ProsemirrorDispatcher): boolean {
    const { $from, $to } = state.selection;
    let nodeToReplace: Node;
    let posOfNodeToReplace: number;

    // If this is a node selection
    if (state.selection instanceof NodeSelection) {
      // Then check the selected node to see if its the other node type
      if (state.selection.node.type === otherNodeType) {
        nodeToReplace = state.selection.node;
        posOfNodeToReplace = $from.pos;
      }
    }

    // If there isn't a node to replace from a node selection
    if (!nodeToReplace) {
      // Then search the ancestors for the other node type
      const foundResolvedPosInfo = resolvedPosFind($from, node => {
        return node.type === otherNodeType;
      });

      if (!foundResolvedPosInfo) {
        return false;
      }

      nodeToReplace = foundResolvedPosInfo.node;
      posOfNodeToReplace = foundResolvedPosInfo.$pos.before(foundResolvedPosInfo.depth);
    }

    if (dispatch) {
      dispatch(state.tr.setNodeMarkup(posOfNodeToReplace, nodeType, nodeToReplace.attrs, nodeToReplace.marks).scrollIntoView());
    }

    return true;
  };
}

/**
 * Creates a command that sinks a definition list item down into an inner definition list.
 * This function is a modified version of ProseMirror's sinkListItem.
 * @param termItemType The NodeType for the definition list's term node.
 * @param descriptionItemType The NodeType for the definition list's description node.
 */
export function sinkDefinitionListItem(termItemType: NodeType, descriptionItemType: NodeType): Command {
  return function (state: EditorState, dispatch?: ProsemirrorDispatcher): boolean {
    const { $from, $to } = state.selection;

    const range = $from.blockRange($to, node => {
      return node.childCount && (node.firstChild.type === termItemType || node.firstChild.type === descriptionItemType);
    });
    if (!range) {
      return false;
    }

    const startIndex = range.startIndex;
    if (startIndex === 0) {
      return false;
    }

    const parent = range.parent;
    const nodeBefore = parent.child(startIndex - 1);
    // If this is the first item in the list then it cannot be sunk
    if (nodeBefore.type !== termItemType && nodeBefore.type !== descriptionItemType) {
      return false;
    }

    if (dispatch) {
      const nestedBefore = nodeBefore.lastChild && nodeBefore.lastChild.type === parent.type;
      const inner = Fragment.from(nestedBefore ? termItemType.create() : null);
      const slice = new Slice(Fragment.from(termItemType.create(null, Fragment.from(parent.type.create(null, inner)))), nestedBefore ? 3 : 1, 0);
      const before = range.start;
      const after = range.end;

      dispatch(state.tr.step(new ReplaceAroundStep(before - (nestedBefore ? 3 : 1), after, before, after, slice, 1, true)).scrollIntoView());
    }

    return true;
  };
}

/**
 * Creates a command that lifts a definition list item up into a wrapping definition list.
 * This function is a modified version of ProseMirror's liftListItem.
 * @param termItemType The NodeType for the definition list's term node.
 * @param descriptionItemType The NodeType for the definition list's description node.
 */
export function liftDefinitionListItem(termItemType: NodeType, descriptionItemType: NodeType): Command {
  return function (state: EditorState, dispatch?: ProsemirrorDispatcher): boolean {
    const { $from, $to } = state.selection;

    const range = $from.blockRange($to, node => {
      return node.childCount > 0 && (node.firstChild.type === termItemType || node.firstChild.type === descriptionItemType);
    });
    if (!range) {
      return false;
    }

    if (!dispatch) {
      return true;
    }

    const parentNodeOfList = $from.node(range.depth - 1);

    // If inside a parent list
    if (parentNodeOfList.type === termItemType || parentNodeOfList.type === descriptionItemType) {
      const itemNodeBeingLifted = resolvedPosFindNode($from, node => node.type === termItemType || node.type === descriptionItemType);
      return liftToOuterList(state, dispatch, itemNodeBeingLifted.type, range);
    } else { // Else in an outer list node
      return liftOutOfList(state, dispatch, range);
    }
  };
}

export function unwrapFromList(itemType: NodeType, listType: NodeType): Command {
  const lift = liftListItem(itemType);

  return function (state: EditorState, dispatch?: ProsemirrorDispatcher): boolean {
    const { $from, $to } = state.selection;

    const range = $from.blockRange($to, node => node.childCount && node.firstChild.type === itemType);
    if (!range) {
      return false;
    }

    // If the item's list type is a different type then do nothing
    if (range.parent.type !== listType) {
      return false;
    }

    return lift(state, dispatch);
  };
}

export function setListType(itemType: NodeType, listType: NodeType): Command {
  return function (state: EditorState, dispatch?: ProsemirrorDispatcher): boolean {
    const { $from, $to } = state.selection;

    const range = $from.blockRange($to, node => node.childCount && node.firstChild.type === itemType);
    if (!range) {
      return false;
    }

    // If the item's list type is the same type then do nothing
    if (range.parent.type === listType) {
      return false;
    }

    if (dispatch) {
      const tr = state.tr;

      tr.setNodeMarkup($from.before(range.depth), listType);
      dispatch(tr.scrollIntoView());
    }

    return true;
  };
}
