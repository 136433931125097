export enum TranslationPackageServices {
  Translation,
  TranslationAndRevision,
  MachineTranslationAndEdit,
  MachineTranslationEditAndRevision
}

export const TranslationPackageServicesDescriptions = {
  [TranslationPackageServices.Translation]: 'Translation Only',
  [TranslationPackageServices.TranslationAndRevision]: 'Translation + Revision',
  [TranslationPackageServices.MachineTranslationAndEdit]: 'Machine Translation + Full Post-Editing',
  [TranslationPackageServices.MachineTranslationEditAndRevision]: 'Machine Translation + Full Post-Editing + Revision'
}