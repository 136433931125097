import { Injectable } from '@angular/core';
import { ProjectStylesheetType } from '@portal-core/project-files/enums/project-stylesheet-type.enum';

/** Regular expression to match Flare css meta comment in a stylesheet content */
const cssMetaRegExp = /\/\*\s*<meta(\s*|(\s+([^/*][^*]*)*))\/>\s*\*+\//m;
/** Regular expression to match Flare branding comment in a stylesheet content */
const brandingRegExp = /\/\*\s*MadCap-Branding[^*]*\*+([^/*][^*]*\*+)*\//m;
/** Regular expression to match Flare table comment in a stylesheet content */
const tableRegExp = /\/\*\s*MadCap\s+Table\s+Style[^*]*\*+([^/*][^*]*\*+)*\//m;

/** Provides methods to work with stylesheets. */
@Injectable({
  providedIn: 'root'
})
export class ProjectStylesheetService {

  /** Validate the given stylesheet content.
   * @param content: The stylesheet content to validate.
   * @returns An Error if the validation fail, otherwise null.
  */
  validate(content: string): Error {
    return null;
  }

  /** Gets the type of the stylesheet.
   * @param content: The stylesheet content.
   * @returns The stylesheet type.
   */
  stylesheetType(content: string): ProjectStylesheetType {
    const brandingDeclaration = this.brandingDeclaration(content);
    if (brandingDeclaration) {
      return ProjectStylesheetType.Branding;
    }
    const tableDeclaration = this.tableDeclaration(content);
    if (tableDeclaration) {
      return ProjectStylesheetType.Table;
    }
    return ProjectStylesheetType.General;
  }

  /** Gets the Flare css meta comment from the given stylesheet content.
   * @param content: The stylesheet content.
   * @returns The Flare css meta comment if found, otherwise null.
  */
  cssMetaDeclaration(content: string): string | null {
    return cssMetaRegExp.exec(content)?.[0] ?? null;
  }

  /** Gets the Flare branding comment from the given stylesheet content.
   * @param content: The stylesheet content.
   * @returns The Flare branding comment if found, otherwise null.
  */
  brandingDeclaration(content: string): string | null {
    return brandingRegExp.exec(content)?.[0] ?? null;
  }

  /** Gets the Flare table comment from the given stylesheet content.
   * @param content: The stylesheet content.
   * @returns The Flare table comment if found, otherwise null.
  */
  tableDeclaration(content: string): string | null {
    return tableRegExp.exec(content)?.[0] ?? null;
  }
}
