import { SetBlockTypeExcludeAttrs } from '@common/flare/constants/set-block-type-exclude-attrs.constant';
import { FlareSchema } from '@common/flare/flare-schema';
import { insertBr } from '@common/prosemirror/commands/br.command';
import { ApplyConditionsCommand, applyConditions } from '@common/prosemirror/commands/conditions.command';
import { insertDropDown, moveFromDropDown, setDropDownHotspot } from '@common/prosemirror/commands/dropdown.command';
import { CreateImageNodeCommand, CreateImagePlaceholdersCommand, insertImage, insertImagePlaceholders } from '@common/prosemirror/commands/image.command';
import { LinkNodeCommand, insertOrEditLink } from '@common/prosemirror/commands/link.command';
import { liftDefinitionListItem, setDefinitionListItemType, sinkDefinitionListItem, splitDefinitionListItem, splitListItem, wrapInList } from '@common/prosemirror/commands/list';
import { MultimediaNodeCommand, insertOrEditMultimedia } from '@common/prosemirror/commands/multimedia.command';
import { appendMultipleChoiceItem } from '@common/prosemirror/commands/multiple-choice.command';
import { LiftFromInlineNodeOptions, SelectNodeCommand, WrapInlineNodeCommand, deleteSelectionAndFill, liftFromInlineNode, selectNode, setBlockType, toggleInlineNode, wrapInInlineNode } from '@common/prosemirror/commands/node';
import { CreateSnippetNodeCommand, insertSnippet } from '@common/prosemirror/commands/snippet.command';
import { CreateTableNodeCommand, insertTable } from '@common/prosemirror/commands/table.command';
import { InsertVariableNodeCommand, insertVariable } from '@common/prosemirror/commands/variable.command';
import { CachedAccessor } from '@common/util/cached-accessor.decorator';
import { chainCommands, deleteSelection } from 'prosemirror-commands';
import { liftListItem, sinkListItem } from 'prosemirror-schema-list';
import { Command } from 'prosemirror-state';

export class FlareCommands {
  static readonly AnnotationLiftFromInlineNodeOptions: LiftFromInlineNodeOptions = {
    meta: 'anno',
    metaValue: 'remove',
    liftAncestorForEmptySelection: true
  };
  static readonly LinkLiftFromInlineNodeOptions: LiftFromInlineNodeOptions = {
    meta: 'link',
    metaValue: 'remove',
    liftAncestorForEmptySelection: true
  };
  static readonly CrossReferenceLiftFromInlineNodeOptions: LiftFromInlineNodeOptions = {
    meta: 'crossref',
    metaValue: 'remove',
    liftAncestorForEmptySelection: true
  };

  constructor(private schema: FlareSchema) { }

  /*
   * Selection
   */
  @CachedAccessor()
  get selectNode(): SelectNodeCommand {
    return selectNode();
  }

  /*
   * Deletion
   */
  @CachedAccessor()
  get deleteSelection(): Command {
    return chainCommands(deleteSelectionAndFill, deleteSelection);
  }

  /*
   * Annotations
   */
  @CachedAccessor()
  get deleteAnnotation(): Command {
    return liftFromInlineNode(this.schema.nodes.madcapannotation, FlareCommands.AnnotationLiftFromInlineNodeOptions);
  }

  @CachedAccessor()
  get insertAnnotation(): WrapInlineNodeCommand {
    return wrapInInlineNode(this.schema.nodes.madcapannotation, {
      meta: 'anno',
      metaValue: 'add',
      limitRangeToOneNode: true,
      wrapWordForEmptySelection: true
    });
  }

  /*
   * Break
   */
  @CachedAccessor()
  get insertBr(): Command {
    return insertBr(this.schema.nodes.hard_break);
  }

  /*
   * Lists
   */
  @CachedAccessor()
  get wrapInBulletList(): Command {
    return wrapInList(this.schema.nodes.bullet_list, this.schema.nodes.paragraph, 'keep');
  }

  @CachedAccessor()
  get wrapInOrderedList(): Command {
    return wrapInList(this.schema.nodes.ordered_list, this.schema.nodes.paragraph, 'keep');
  }

  @CachedAccessor()
  get wrapInDefinitionList(): Command {
    return wrapInList(this.schema.nodes.definition_list, this.schema.nodes.mcCentralContainer, 'move-to-item');
  }

  @CachedAccessor()
  get changeDefinitionDescriptionToDefinitionTerm(): Command {
    return setDefinitionListItemType(this.schema.nodes.definition_term, this.schema.nodes.definition_description);
  }

  @CachedAccessor()
  get changeDefinitionTermToDefinitionDescription(): Command {
    return setDefinitionListItemType(this.schema.nodes.definition_description, this.schema.nodes.definition_term);
  }

  @CachedAccessor()
  get indentListItem(): Command {
    return sinkListItem(this.schema.nodes.list_item);
  }

  @CachedAccessor()
  get outdentListItem(): Command {
    return liftListItem(this.schema.nodes.list_item);
  }

  @CachedAccessor()
  get indentDefinitionListItem(): Command {
    return sinkDefinitionListItem(this.schema.nodes.definition_term, this.schema.nodes.definition_description);
  }

  @CachedAccessor()
  get outdentDefinitionListItem(): Command {
    return liftDefinitionListItem(this.schema.nodes.definition_term, this.schema.nodes.definition_description);
  }

  @CachedAccessor()
  get indentAnyListItem(): Command {
    return chainCommands(this.indentListItem, this.indentDefinitionListItem);
  }

  @CachedAccessor()
  get outdentAnyListItem(): Command {
    return chainCommands(this.outdentListItem, this.outdentDefinitionListItem);
  }

  @CachedAccessor()
  get splitListItem(): Command {
    return splitListItem(this.schema.nodes.list_item);
  }

  @CachedAccessor()
  get splitDefinitionListItem(): Command {
    return splitDefinitionListItem(this.schema.nodes.definition_term, this.schema.nodes.definition_description);
  }

  /*
   * Headings
   */
  @CachedAccessor()
  get changeBlockToH1(): Command {
    return setBlockType(this.schema.nodes.h1, SetBlockTypeExcludeAttrs);
  }

  @CachedAccessor()
  get changeBlockToH2(): Command {
    return setBlockType(this.schema.nodes.h2, SetBlockTypeExcludeAttrs);
  }

  @CachedAccessor()
  get changeBlockToH3(): Command {
    return setBlockType(this.schema.nodes.h3, SetBlockTypeExcludeAttrs);
  }

  @CachedAccessor()
  get changeBlockToH4(): Command {
    return setBlockType(this.schema.nodes.h4, SetBlockTypeExcludeAttrs);
  }

  @CachedAccessor()
  get changeBlockToH5(): Command {
    return setBlockType(this.schema.nodes.h5, SetBlockTypeExcludeAttrs);
  }

  @CachedAccessor()
  get changeBlockToH6(): Command {
    return setBlockType(this.schema.nodes.h6, SetBlockTypeExcludeAttrs);
  }

  /*
   * Multiple Choice
   */
  @CachedAccessor()
  get appendMultipleChoiceItem(): Command {
    return appendMultipleChoiceItem([this.schema.nodes.madcapmultiplechoiceitem, this.schema.nodes.madcapquestion], this.schema.nodes.madcapmultiplechoiceitem);
  }

  /*
   * Paragraph
   */
  @CachedAccessor()
  get changeBlockToParagraph(): Command {
    return setBlockType(this.schema.nodes.paragraph, SetBlockTypeExcludeAttrs);
  }

  /*
   * Images
   */
  // Used for inserting a project image
  @CachedAccessor()
  get insertImage(): CreateImageNodeCommand {
    return insertImage(this.schema.nodes.image);
  }

  // Used for inserting new images that must be added to the server first
  @CachedAccessor()
  get insertImagePlaceholders(): CreateImagePlaceholdersCommand {
    return insertImagePlaceholders(this.schema.nodes.image);
  }

  /*
   * Variables
   */
  @CachedAccessor()
  get insertVariable(): InsertVariableNodeCommand {
    return insertVariable(this.schema.nodes.madcapvariable);
  }

  /*
   * Multimedia
   */
  @CachedAccessor()
  get insertOrEditMultimedia(): MultimediaNodeCommand {
    return insertOrEditMultimedia(this.schema.nodes.madcapmultimedia);
  }
  @CachedAccessor()
  get insertOrEdit3dModel(): MultimediaNodeCommand {
    return insertOrEditMultimedia(this.schema.nodes.madcapmodel3d);
  }

  /*
  * Links
  */
  @CachedAccessor()
  get insertOrEditLink(): LinkNodeCommand {
    return insertOrEditLink(this.schema.nodes.link, this.schema.nodes.madcapbookmark);
  }

  @CachedAccessor()
  get insertOrEditCrossRefLink(): LinkNodeCommand {
    return insertOrEditLink(this.schema.nodes.madcapcrossreference, this.schema.nodes.madcapbookmark);
  }

  @CachedAccessor()
  get deleteLink(): Command {
    return liftFromInlineNode(this.schema.nodes.link, FlareCommands.LinkLiftFromInlineNodeOptions);
  }

  @CachedAccessor()
  get deleteCrossReference(): Command {
    return liftFromInlineNode(this.schema.nodes.madcapcrossreference, FlareCommands.CrossReferenceLiftFromInlineNodeOptions);
  }

  /*
  * Snippets
  */
  @CachedAccessor()
  get insertSnippet(): CreateSnippetNodeCommand {
    return insertSnippet(this.schema.nodes.madcapsnippettext, this.schema.nodes.madcapsnippetblock);
  }

  /*
   * Tables
   */
  @CachedAccessor()
  get insertTable(): CreateTableNodeCommand {
    return insertTable(this.schema.nodes.table, this.schema.nodes.tbody, this.schema.nodes.table_row, this.schema.nodes.table_cell, this.schema.nodes.colgroup, this.schema.nodes.col);
  }

  /*
   * Typography
   */
  @CachedAccessor()
  get wrapInBold(): Command {
    return wrapInInlineNode(this.schema.nodes.b, { wrapParentForEmptySelection: true });
  }

  @CachedAccessor()
  get unwrapBold(): Command {
    return liftFromInlineNode(this.schema.nodes.b);
  }

  @CachedAccessor()
  get toggleBold(): Command {
    return toggleInlineNode(this.schema.nodes.b, { wrapParentForEmptySelection: true });
  }

  @CachedAccessor()
  get wrapInItalics(): Command {
    return wrapInInlineNode(this.schema.nodes.i, { wrapParentForEmptySelection: true });
  }

  @CachedAccessor()
  get unwrapItalics(): Command {
    return liftFromInlineNode(this.schema.nodes.i);
  }

  @CachedAccessor()
  get toggleItalics(): Command {
    return toggleInlineNode(this.schema.nodes.i, { wrapParentForEmptySelection: true });
  }

  @CachedAccessor()
  get wrapInUnderline(): Command {
    return wrapInInlineNode(this.schema.nodes.u, { wrapParentForEmptySelection: true });
  }

  @CachedAccessor()
  get unwrapUnderline(): Command {
    return liftFromInlineNode(this.schema.nodes.u);
  }

  @CachedAccessor()
  get toggleUnderline(): Command {
    return toggleInlineNode(this.schema.nodes.u, { wrapParentForEmptySelection: true });
  }

  /*
   * Drop-downs
   */
  @CachedAccessor()
  get insertDropDown(): Command {
    return insertDropDown(this.schema.nodes.madcapdropdown, this.schema.nodes.madcapdropdownhead, this.schema.nodes.madcapdropdownhotspot, this.schema.nodes.madcapdropdownbody);
  }

  @CachedAccessor()
  get setDropDownHotspot(): Command {
    return setDropDownHotspot(this.schema.nodes.madcapdropdownhead, this.schema.nodes.madcapdropdownhotspot);
  }

  @CachedAccessor()
  get moveFromDropDown(): Command {
    return moveFromDropDown(this.schema.nodes.madcapdropdown, this.schema.nodes.madcapdropdownbody);
  }

  /*
   * Conditions
   */
  @CachedAccessor()
  get setConditions(): ApplyConditionsCommand {
    return applyConditions();
  }
}
