import { CdkScrollable } from '@angular/cdk/scrolling';
import { ComponentFactoryResolver, Injectable, Injector, Type } from '@angular/core';
import { FlareElementAttributeWhitelist } from '@common/flare/constants/flare-element-attribute-whitelist.constant';
import { FlareElementEmptyNodeWhitelist } from '@common/flare/constants/flare-element-empty-node-whitelist.constant';
import { FlareElementStyleWhitelist } from '@common/flare/constants/flare-element-style-whitelist.constant';
import { FlareCommands } from '@common/flare/flare-commands';
import { FlareSchema } from '@common/flare/flare-schema';
import { ProsemirrorFlareDOMParser } from '@common/flare/prosemirror-flare-dom-parser';
import { ProsemirrorFlareXMLSerializer } from '@common/flare/prosemirror-flare-xml-serializer';
import { flareElementEmptyNodeWhitelistMatcher } from '@common/flare/util/flare-element-empty-node-whitelist-matcher';
import { MetaDataValues } from '@common/meta-data/types/meta-data-values.type';
import { baseKeymap } from '@common/prosemirror/commands/base-key-map';
import { splitInlinesNearestBlock } from '@common/prosemirror/commands/node';
import { AppliedConditionsPluginOptions, appliedConditionsPlugin } from '@common/prosemirror/plugins/applied-conditions.plugin';
import { clipboardImageResolverPlugin } from '@common/prosemirror/plugins/clipboard-image-resolver.plugin';
import { clipboardParserPlugin } from '@common/prosemirror/plugins/clipboard-parser.plugin';
import { clipboardSerializerPlugin } from '@common/prosemirror/plugins/clipboard-serializer.plugin';
import { emptyNodeRemovalPlugin } from '@common/prosemirror/plugins/empty-node-removal.plugin';
import { guidAttrPastePlugin } from '@common/prosemirror/plugins/guid-attr-paste.plugin';
import { GutterPluginOptions, gutterPlugin } from '@common/prosemirror/plugins/gutter.plugin';
import { imageUploadPlugin } from '@common/prosemirror/plugins/image-upload.plugin';
import { MetaDataPluginOptions, metaDataPlugin } from '@common/prosemirror/plugins/meta-data';
import { MetaDataLoaderPluginOptions, metaDataLoaderPlugin } from '@common/prosemirror/plugins/meta-data-loader';
import { pasteCleanerPlugin } from '@common/prosemirror/plugins/paste-cleaner.plugin';
import { PasteNormalizerPluginOptions, pasteNormalizerPlugin } from '@common/prosemirror/plugins/paste-normalizer.plugin';
import { SelectionNormalizerPluginOptions, selectionNormalizerPlugin } from '@common/prosemirror/plugins/selection-normalizer.plugin';
import { tableDropRemovalPlugin } from '@common/prosemirror/plugins/table-drop-removal.plugin';
import { TrackedChangesPluginOptions, trackedChangesPlugin } from '@common/prosemirror/plugins/tracked-changes';
import { trackedChangesCleanUpPlugin } from '@common/prosemirror/plugins/tracked-changes-clean-up';
import { ViewPluginOptions, viewPlugin } from '@common/prosemirror/plugins/view.plugin';
import { vscodePasterPlugin } from '@common/prosemirror/plugins/vscode-paster.plugin';
import { resolveCentralUrl, resolvePath } from '@common/util/path';
import { environment } from '@env/environment';
import { ApiService } from '@portal-core/auth/services/api.service';
import { Attachment } from '@portal-core/data/common/models/attachment.model';
import { FileService } from '@portal-core/general/services/file.service';
import { CommittedItem } from '@portal-core/project-files/models/committed-item.model';
import { MetaDataApiService } from '@portal-core/project-files/services/meta-data-api.service';
import { ComponentNodeViewOptions } from '@portal-core/text-editor/models/component-node-view-options.model';
import { ContentNodeViewOptions } from '@portal-core/text-editor/models/content-node-view-options.model';
import { InsertImageEvent } from '@portal-core/text-editor/models/insert-image.event';
import { SnippetNodeViewOptions } from '@portal-core/text-editor/models/snippet-node-view-options.model';
import { ImageNodeView } from '@portal-core/text-editor/node-views/image.node-view';
import { MultipleChoiceItemNodeView } from '@portal-core/text-editor/node-views/multiple-choice-item.node-view';
import { SnippetNodeView } from '@portal-core/text-editor/node-views/snippet.node-view';
import { VariableNodeView } from '@portal-core/text-editor/node-views/variable.node-view';
import { NodeToolbarPopupPluginOptions, NodeToolbarPopupPluginView } from '@portal-core/text-editor/plugin-views/node-toolbar-popup.plugin-view';
import { TextEditorApiService } from '@portal-core/text-editor/services/text-editor-api.service';
import { DynamicViewComponentInjection } from '@portal-core/text-editor/types/dynamic-view-component-injector.type';
import { GetPosForNodeView } from '@portal-core/text-editor/types/nodeview-get-pos.type';
import { ElementOverlayService } from '@portal-core/ui/overlay/services/element.overlay.service';
import { Cancelable } from 'lodash';
import { chainCommands, baseKeymap as pmBaseKeymap, toggleMark } from 'prosemirror-commands';
import { gapCursor } from 'prosemirror-gapcursor';
import { history, redo, undo } from 'prosemirror-history';
import { keymap } from 'prosemirror-keymap';
import { ProseMirrorNode, Schema } from 'prosemirror-model';
import { liftListItem, splitListItem as pmSplitListItem, sinkListItem } from 'prosemirror-schema-list';
import { Command, Plugin } from 'prosemirror-state';
import { goToNextCell, tableEditing } from 'prosemirror-tables';
import { Decoration, EditorView, NodeViewConstructor } from 'prosemirror-view';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export interface FlareNodeViewOptions {
  image?: ContentNodeViewOptions;
  madCapSnippet?: SnippetNodeViewOptions;
  madCapVariable?: ComponentNodeViewOptions;
}

export interface FlarePluginOptions {
  gutter?: GutterPluginOptions;
  metaData?: MetaDataPluginOptions;
  metaDataLoader?: MetaDataLoaderPluginOptions;
  nodeToolbarPopup?: NodeToolbarPopupPluginOptions;
  pasteNormalizer?: PasteNormalizerPluginOptions;
  trackedChanges?: TrackedChangesPluginOptions;
  clipboardImageResolver?: { onPastedImages: (imageInfos: InsertImageEvent[]) => void };
  view?: ViewPluginOptions;
  keymaps?: Dictionary<Command>;
  selectionNormalizer?: SelectionNormalizerPluginOptions;
  appliedConditions?: AppliedConditionsPluginOptions;
}

export interface RichTextPluginOptions {
  pasteNormalizer?: PasteNormalizerPluginOptions;
  view?: ViewPluginOptions;
}

// absolute path => commitId
export type SnippetCommitIdMap = Map<string, string>;

@Injectable({
  providedIn: 'root'
})
export class TextEditorService {
  /**
   * Shared by all flare text editors to update themselves if a snippet was committed through the edit snippet popup. Works in conjunction with refreshSnippet$
   */
  private snippetCommitIdMap: SnippetCommitIdMap = new Map<string, string>();
  /**
   * All snippets observe this and are ready to update when the commitId changes. Each snippet rebuilds their src with the latest commitId and if the src changes, the snippet rerenders itself.
   */
  public refreshSnippet$: Subject<string> = new Subject<string>();

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private apiService: ApiService,
    private elementOverlayService: ElementOverlayService,
    private textEditorApiService: TextEditorApiService,
    private fileService: FileService,
    private metaDataApiService: MetaDataApiService
  ) { }

  readonly flareTextEditorDocMaxCharLength: number = environment.flareTextEditorDocMaxCharLength ? parseInt(environment.flareTextEditorDocMaxCharLength, 10) : 0;

  createFlareNodeViews(schema: FlareSchema, options: FlareNodeViewOptions = {}): Dictionary<NodeViewConstructor> {
    const nodeViews: Dictionary<NodeViewConstructor> = {};
    const create = this.createDynamicViewComponent.bind(this);

    if (schema.nodes.image) {
      nodeViews.image = (node: ProseMirrorNode, editorView: EditorView, getPos: GetPosForNodeView, decorations: Decoration[]) => {
        return new ImageNodeView(node, editorView, getPos, decorations, create, options.image);
      };
    }

    if (schema.nodes.madcapmultiplechoiceitem) {
      nodeViews.madcapmultiplechoiceitem = (node: ProseMirrorNode, editorView: EditorView, getPos: GetPosForNodeView, decorations: Decoration[]) => {
        return new MultipleChoiceItemNodeView(node, editorView, getPos, decorations, create);
      };
    }

    if (schema.nodes.madcapsnippetblock) {
      nodeViews.madcapsnippetblock = (node: ProseMirrorNode, editorView: EditorView, getPos: GetPosForNodeView, decorations: Decoration[]) => {
        return new SnippetNodeView(node, editorView, getPos, decorations, create, options.madCapSnippet);
      };
    }

    if (schema.nodes.madcapsnippettext) {
      nodeViews.madcapsnippettext = (node: ProseMirrorNode, editorView: EditorView, getPos: GetPosForNodeView, decorations: Decoration[]) => {
        return new SnippetNodeView(node, editorView, getPos, decorations, create, options.madCapSnippet);
      };
    }

    if (schema.nodes.madcapvariable) {
      nodeViews.madcapvariable = (node: ProseMirrorNode, editorView: EditorView, getPos: GetPosForNodeView, decorations: Decoration[]) => {
        return new VariableNodeView(node, editorView, getPos, decorations, create, options.madCapVariable);
      };
    }

    return nodeViews;
  }

  createFlarePlugins(schema: FlareSchema, options: FlarePluginOptions = {}): Plugin[] {
    const flareCommands = new FlareCommands(schema);
    const plugins: Plugin[] = [];

    options.keymaps ? plugins.push(keymap(options.keymaps)) : null;
    plugins.push(
      history(),
      // Lists
      keymap({
        'Enter': chainCommands(
          flareCommands.splitListItem,
          flareCommands.splitDefinitionListItem,
          flareCommands.appendMultipleChoiceItem
        ),
        'Tab': flareCommands.indentAnyListItem,
        'Shift-Tab': flareCommands.outdentAnyListItem
      }),
      // Dropdowns
      keymap({
        'Enter': flareCommands.moveFromDropDown
      }),
      // Tables
      keymap({
        'Tab': goToNextCell(1),
        'Shift-Tab': goToNextCell(-1)
      }),
      keymap({
        'Mod-z': undo,
        'Mod-y': redo
      }),
      keymap({
        'Shift-Enter': flareCommands.insertBr
      }),
      // Typography key bindings
      keymap({
        'Mod-b': flareCommands.toggleBold,
        'Mod-B': flareCommands.toggleBold,
        'Mod-i': flareCommands.toggleItalics,
        'Mod-I': flareCommands.toggleItalics,
        'Mod-u': flareCommands.toggleUnderline
      }),
      keymap(baseKeymap),
      keymap({
        'Enter': splitInlinesNearestBlock
      }),
      gapCursor(),
      // Clipboard
      clipboardSerializerPlugin({
        clipboardSerializer: new ProsemirrorFlareXMLSerializer(schema)
      }),
      clipboardParserPlugin({
        clipboardParser: ProsemirrorFlareDOMParser.fromSchema(schema)
      }),
      pasteNormalizerPlugin({
        attributesWhitelist: FlareElementAttributeWhitelist,
        emptyNodeWhitelist: FlareElementEmptyNodeWhitelist,
        emptyNodeWhitelistMatcher: flareElementEmptyNodeWhitelistMatcher,
        pageBreakReplacement: { tagName: 'MadCap:pageBreak' },
        styleWhitelist: FlareElementStyleWhitelist,
        ...options.pasteNormalizer
      }),
      vscodePasterPlugin({
        codeNodeTypesOrNames: ['madcapcodesnippet', 'madcapcodesnippetbody']
      }),
      guidAttrPastePlugin()
    );

    plugins.push(emptyNodeRemovalPlugin());

    if (options.metaData) {
      plugins.push(metaDataPlugin(options.metaData));
    }

    if (options.metaDataLoader) {
      plugins.push(metaDataLoaderPlugin(options.metaDataLoader));
    }

    if (options.trackedChanges) {
      plugins.push(trackedChangesPlugin(options.trackedChanges));
      plugins.push(trackedChangesCleanUpPlugin());
    }

    if (options.clipboardImageResolver) {
      plugins.push(clipboardImageResolverPlugin({
        imageType: schema.nodes.image,
        onPastedImages: options.clipboardImageResolver.onPastedImages,
        imagePlaceholdersCommand: flareCommands.insertImagePlaceholders,
        getFile: this.fileService.convertBase64ImageToFile
      }));
    }

    if (options.nodeToolbarPopup && !options.nodeToolbarPopup.isReadonly && options.view) {
      plugins.push(viewPlugin(new NodeToolbarPopupPluginView(this.elementOverlayService, options.nodeToolbarPopup), options.view));
    }

    if (options.gutter) {
      plugins.push(gutterPlugin(options.gutter));
    }

    plugins.push(tableDropRemovalPlugin());

    plugins.push(imageUploadPlugin());

    if (options.selectionNormalizer) {
      plugins.push(selectionNormalizerPlugin(options.selectionNormalizer));
    }

    if (options.appliedConditions) {
      plugins.push(appliedConditionsPlugin(options.appliedConditions));
    }

    // prosemirror-tables suggests using this plugin last because of how it handles mouse and arrow key events
    plugins.push(tableEditing({ allowTableNodeSelection: true }));

    return plugins;
  }

  createMetaDataLoaderPluginOptions(committedItem: CommittedItem, metaDataUpdater: Subject<MetaDataValues>): MetaDataLoaderPluginOptions {
    return {
      // Define the handler called by metaDataLoader when a topic is loading.
      onLoadMetaData$: () => {
        // Make a request to get the meta data
        return this.metaDataApiService.getMetaData$(['variables', 'conditionTags'], committedItem);
      },
      metaDataSource: metaDataUpdater
    };
  }

  createRichTextPlugins(schema: Schema, options?: RichTextPluginOptions): Plugin[] {
    const plugins: Plugin[] = [];

    plugins.push(
      history(),
      // List key bindings
      keymap({
        'Enter': pmSplitListItem(schema.nodes.list_item),
        'Tab': sinkListItem(schema.nodes.list_item),
        'Shift-Tab': liftListItem(schema.nodes.list_item)
      }),
      // History key bindings
      keymap({
        'Mod-z': undo,
        'Mod-y': redo
      }),
      // Hard break key binding
      keymap({
        'Shift-Enter': (state, dispatch) => {
          dispatch(state.tr.replaceSelectionWith(schema.nodes.hard_break.create()).scrollIntoView());
          return true;
        }
      }),
      // Typography key bindings
      keymap({
        'Mod-b': toggleMark(schema.marks.strong),
        'Mod-B': toggleMark(schema.marks.strong),
        'Mod-i': toggleMark(schema.marks.em),
        'Mod-I': toggleMark(schema.marks.em),
        'Mod-u': toggleMark(schema.marks.u)
      }),
      keymap(pmBaseKeymap),
      gapCursor(),
      pasteCleanerPlugin({
        ignoredNodeTypesOrNames: ['image']
      }),
      pasteNormalizerPlugin(options.pasteNormalizer),
      vscodePasterPlugin({
        codeNodeTypesOrNames: ['code_block']
      })
    );

    return plugins;
  }

  createRichTextNodeViews(): Dictionary<NodeViewConstructor> {
    return {};
  }

  uploadImage$(fileId: number, image: FormData): Observable<Attachment> {
    return this.textEditorApiService.uploadImage$(fileId, image);
  }

  /**
   * Returns the URI for content that is referenced from a flare document (eg an image or snippet.)
   * This functions expects two paths:
   * The path of the file that contains the content.
   * And the path of the content as defined in that file.
   * If the path of the content is relative to the file then this function will resolve that path on its own.
   * For example, if the URI is needed for an image then the contentPath is the value of the src attribute on the image tag.
   * @param projectId The project id that the file belongs to.
   * @param commitIdOrBranchName  The commit id or branch name that the file exists in.
   * @param filePath The path of the file that the content is referenced from.
   * @param contentPath The path of the content being referenced from the file. This is the content path as defined within the file.
   */
  buildFlareContentApiUri(projectId: number, commitIdOrBranchName: string, filePath: string, contentPath: string): string {
    return `${this.apiService.centralContentBaseUri}/${projectId}/${commitIdOrBranchName}/${resolvePath(filePath, contentPath)}`;
  }

  /**
   * Resolves a URL using the central protocol to a relative URL that contains the Central instance in the path.
   * @param url The URL to resolve.
   * @returns The resolved URL.
   */
  resolveCentralUrl(url: string): string {
    return resolveCentralUrl(url, this.apiService.centralInstanceBlobPathPrefix);
  }

  getFlareSnippet$(snippetContentApiUri: string): Observable<string> {
    return this.textEditorApiService.getFlareSnippet$(snippetContentApiUri);
  }

  private createDynamicViewComponent<T>(component: Type<T>): DynamicViewComponentInjection<T> {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    const element = document.createElement(componentFactory.selector);

    return {
      componentRef: componentFactory.create(this.injector, null, element),
      element
    };
  }

  createCommonNodeViews(
    schema: FlareSchema,
    filePath: string,
    projectId: number,
    commitId: string,
    debouncedReflowGutterLayout?: Function & Cancelable,
    metaData$?: Observable<MetaDataValues>,
    appliedConditionsPluginOptions?: AppliedConditionsPluginOptions,
    viewPluginOverlay$?: BehaviorSubject<HTMLElement>,
    editorScrollable?: CdkScrollable,
    parentSnippetPaths: string[] = [],
  ): Dictionary<NodeViewConstructor> {
    return this.createFlareNodeViews(schema, {
      image: {
        buildContentApiUri: contentPath => this.buildFlareContentApiUri(projectId, commitId, filePath, contentPath),
        onLoad: () => debouncedReflowGutterLayout(),
        resolveCentralUrl: url => this.resolveCentralUrl(url)
      },
      madCapSnippet: {
        buildContentApiUri: contentPath => {
          // Resolve the full path of the snippet in the project
          const resolvedPath = resolvePath(filePath ?? '', contentPath ?? '');

          const latestCommitId = this.getLatestSnippetCommitId(resolvedPath, commitId);
          return this.buildFlareContentApiUri(projectId, latestCommitId, filePath, contentPath);
        },
        nodeViews: (snippetPath, newParentSnippetPaths) => this.createCommonNodeViews(schema, snippetPath, projectId, commitId, debouncedReflowGutterLayout, metaData$, appliedConditionsPluginOptions, viewPluginOverlay$, editorScrollable, newParentSnippetPaths),
        onLoad: () => debouncedReflowGutterLayout(),
        parentFilePath: filePath,
        parentSnippetPaths: parentSnippetPaths,
        plugins: () => this.createSnippetPlugins(schema, metaData$, appliedConditionsPluginOptions, viewPluginOverlay$, editorScrollable),
        resolveCentralUrl: url => this.resolveCentralUrl(url),
        schema: schema,
        refresh$: this.refreshSnippet$
      },
      madCapVariable: {
        onLoad: () => debouncedReflowGutterLayout()
      }
    });
  }

  private createSnippetPlugins(
    schema: FlareSchema,
    metaData$: Observable<MetaDataValues>,
    appliedConditionsPluginOptions: AppliedConditionsPluginOptions,
    viewPluginOverlay$: BehaviorSubject<HTMLElement>,
    editorScrollable: CdkScrollable
  ): Plugin[] {
    return this.createFlarePlugins(schema, {
      metaData: { metaData$: metaData$ },
      appliedConditions: appliedConditionsPluginOptions,
      view: {
        overlay$: viewPluginOverlay$,
        scrollable: editorScrollable
      }
    });
  }

  /**
   * Returns the latest commit id for the snippet absolute path. Adds and returns the old commit id if no commits exist yet for the absolute path.
   * @param absolutePath The path of the snippet that the content is referenced from.
   * @param oldCommitId The last commit id of the snippet that the requester is aware of.
   */
  getLatestSnippetCommitId(absolutePath: string, oldCommitId: string): string {
    const commitId = this.snippetCommitIdMap.get(absolutePath);
    // Set to default commitId if it doesn't exist
    if (commitId == undefined) {
      this.setSnippetCommitId(absolutePath, oldCommitId);
    }

    return commitId ?? oldCommitId;
  }

  setSnippetCommitId(absolutePath: string, oldCommitId: string) {
    this.snippetCommitIdMap.set(absolutePath, oldCommitId);
  }

  clearSnippetCommitIds() {
    this.snippetCommitIdMap.clear();
  }
}
